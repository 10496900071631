<template>
  <div class="d-flex align-center justify-center" style="height: 100%">
    <div class="card">
      <h1 class="title">Payment Info</h1>
      <table>
        <tbody>
          <tr>
            <td>Card holder name:</td>
            <td>{{ getData.cardName }}</td>
          </tr>
          <tr>
            <td>Card number:</td>
            <td>{{ getData.cardNumber }}</td>
          </tr>
          <tr>
            <td>Amount:</td>
            <td>{{ getData.cardAmount }}{{ getData.cardCurrency[0] }}</td>
          </tr>
          <tr>
            <td>Date:</td>
            <td>{{ getData.date }}</td>
          </tr>
          <tr>
            <td>Time:</td>
            <td>{{ getData.time }}</td>
          </tr>
        </tbody>
      </table>
      <v-btn size="x-large" color="success" class="button" @click="save"
        >Download</v-btn
      >
      <div class="d-flex align-center justify-space-between image-wrapper">
        <v-img class="first" src="../assets/images/logo.png" />
        <v-img class="second" src="../assets/images/pci.png" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import jsPDF from "jspdf";

export default {
  name: "SuccessView",
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["getData"]),
  },
  methods: {
    save() {
      const doc = new jsPDF();
      const pageWidth = doc.internal.pageSize.getWidth();
      const text = "Payment Info";
      const textWidth = doc.getTextWidth(text);
      const x = (pageWidth - textWidth) / 2;

      doc.text(text, x, 15);

      const tableData = [
        { header: "Card holder name:", value: this.getData.cardName },
        { header: "Card number:", value: this.getData.cardNumber },
        {
          header: "Amount:",
          value: `${this.getData.cardAmount}${this.getData.cardCurrency[0]}`,
        },
        { header: "Date:", value: this.getData.date },
        { header: "Time:", value: this.getData.time },
      ];

      const startY = 20;
      const cellWidth = 60;
      const cellHeight = 10;
      const fontSize = 12;

      doc.setFontSize(fontSize);
      tableData.forEach((data, index) => {
        const x = 45;
        const y = startY + index * cellHeight;
        doc.cell(x, y, cellWidth, cellHeight, data.header);
        doc.cell(x + cellWidth, y, cellWidth, cellHeight, data.value);
      });

      const image = new Image();
      image.src = require("@/assets/images/logo.png");
      image.onload = function () {
        const imageWidth = 50;
        const imageHeight = imageWidth * (image.height / image.width);
        const imageX = (pageWidth - imageWidth) / 2;
        const imageY = startY + tableData.length * cellHeight + 10;

        doc.addImage(image, "JPEG", imageX, imageY, imageWidth, imageHeight);

        doc.save("receipt.pdf");
      };
    },
  },
};
</script>

<style scoped lang="scss">
.title {
  font-weight: 600;
  font-size: 24px;
  line-height: 1.4;
  color: #333333;
  text-align: center;
  margin-bottom: 70px;
}
table {
  border-collapse: collapse;
  width: 100%;
  margin-bottom: 20px;
  td {
    font-size: 12px;
    line-height: 1.5;
    color: #333333;
    width: 65%;
    border: 1px solid #333333;
    padding: 13px 32px;
  }
  td:first-child {
    width: 35%;
    padding: 13px 15px;
    font-size: 10px;
  }
}
.card {
  max-width: 600px;
  width: 90%;
  margin: 0 auto;
  box-shadow: 0px 8px 60px rgba(90, 116, 148, 0.4);
  border-radius: 16px;
  padding: 50px 10px 30px;
  background: linear-gradient(
    110.42deg,
    rgba(251, 237, 241, 0.9) 2.17%,
    rgba(245, 243, 248, 0.9) 30.58%,
    rgba(249, 249, 249, 0.9) 54.01%,
    rgba(245, 243, 248, 0.9) 76.43%,
    rgba(251, 237, 241, 0.9) 100%
  );
}
.button {
  border-radius: 8px;
  font-size: 20px;
  font-weight: 900;
  line-height: 1.4;
  display: block;
  margin: 0 auto;
  width: 100%;
  margin-bottom: 30px;
}
.image-wrapper {
  flex-direction: column;
}
.first {
  width: 170px;
  max-width: 170px;
  margin-bottom: 20px;
}
.second {
  width: 87px;
  max-width: 87px;
}
@media screen and (min-width: 744px) {
  table {
    margin-bottom: 30px;
    td {
      padding: 11px 20px;
      font-size: 12px;
    }
  }
  .card {
    padding: 70px 30px;
  }
  .title {
    margin-bottom: 40px;
  }
  .image-wrapper {
    flex-direction: row;
  }
  .first {
    margin-bottom: 0;
  }
}
</style>
